import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import GatsbyLink from '../gatsby-link'
import { StyledFullWidthSection, StyledGrid, StyledSmallWrapper } from '../styles'
import styled from 'styled-components'
import { devices } from '../../lib/constants'
import WhitePaperButton from '../whitepaper-button'

const StyledContentSection = styled(StyledFullWidthSection)`
  text-align: center;

  ul,
  ol {
    display: inline-block;
    text-align: left;
  }

  h2 {
    text-align: center;
    display: block;
  }

  .gatsby-image-wrapper {
    justify-self: center;
  }

  @media ${devices.tablet} {
    text-align: left;

    ${StyledGrid} {
      grid-template-columns: auto 1fr;
    }
  }
`

const ContentSection = ({ primary, whitePaper }) => {
  const image = getImage(primary.image)

  return (
    <StyledContentSection bgColor="--color-dark" color="--color-light" bgImage="0.05">
      <StyledSmallWrapper>
        <h2 className="font-gradient-grey header-size-large">{primary.section_title.text}</h2>
        <StyledGrid columns="2" alignItems="flex-start">
          <GatsbyImage image={image} alt={primary.image.alt || primary.section_title.text} />
          <div>
            <RichText render={primary.content.raw} serializeHyperlink={GatsbyLink} />
            {whitePaper.url && (
              <WhitePaperButton whitePaper={whitePaper}>{whitePaper.button}</WhitePaperButton>
            )}
          </div>
        </StyledGrid>
      </StyledSmallWrapper>
    </StyledContentSection>
  )
}

export default ContentSection

export const contentSectionArticleFragment = graphql`
  fragment contentSectionArticleFragment on PrismicArticleDataBodyContentSectie {
    id
    slice_label
    slice_type
    primary {
      section_title {
        text
      }
      content {
        raw
      }
      image {
        alt
        gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
      }
    }
  }
`
