import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'

import GatsbyLink from '../gatsby-link'
import { devices } from '../../lib/constants'
import { StyledFullWidthSection, StyledSmallWrapper } from '../styles'

const StyledIdonList = styled.ul`
  @media ${devices.tablet} {
    column-count: 2;
    column-gap: 4rem;
  }

  li {
    display: flex;
    align-items: center;
    margin: 2rem 0;

    &:first-of-type {
      margin-top: 0;
    }

    div {
      width: 40px;
      height: 40px;
      margin-right: 1rem;

      @media ${devices.tablet} {
        width: 60px;
        height: 60px;
      }
    }

    span {
      font-size: 2rem;
      font-weight: var(--font-body-weight-medium);
    }
  }
`

const PageContent = ({ primary, items }) => {
  const style = {
    backgroundColor: primary.background_color,
    color: primary.content_color,
  }

  return (
    <StyledFullWidthSection bgImage="0" style={style} bgColor={primary.background_color}>
      <StyledSmallWrapper fontSize="2.6rem">
        {primary.page_content_title.text && (
          <h2 className="header-size-large">{primary.page_content_title.text}</h2>
        )}
        <RichText render={primary.page_content_content.raw} serializeHyperlink={GatsbyLink} />
        {items.length && (
          <StyledIdonList>
            {items.map((item) => (
              <li key={item.label}>
                <div dangerouslySetInnerHTML={{ __html: item.svg_icon }} />
                <span>{item.label}</span>
              </li>
            ))}
          </StyledIdonList>
        )}
      </StyledSmallWrapper>
    </StyledFullWidthSection>
  )
}

export default PageContent

export const pageContentPageFragment = graphql`
  fragment pageContentPageFragment on PrismicPageDataBodyPageContent {
    id
    slice_type
    primary {
      content_color
      background_color
      page_content_title {
        text
      }
      page_content_content {
        raw
      }
    }
    items {
      label
      svg_icon
    }
  }
`
