import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { RichText } from 'prismic-reactjs'

import linkResolver from '../../lib/link-resolver'

import GatsbyLink from '../gatsby-link'
import { Button, StyledFullWidthSection, StyledGrid, StyledMain } from '../styles'
import styled from 'styled-components'

const StyledHero = styled(StyledFullWidthSection)`
  h3 {
    color: var(--color-secondary);
    margin-top: calc(var(--spacer-small) * -0.5);
    margin-bottom: var(--spacer-medium);
  }

  .gatsby-image-wrapper {
    border-radius: var(--border-radius-medium);
    -webkit-mask-image: -webkit-radial-gradient(white, black); // safari fix
  }
`

const Hero = ({ primary, index }) => {
  const link = linkResolver(primary.button_link)
  const image = getImage(primary.image)

  useEffect(() => {
    const className = 'hero-offset'
    if (index === 0) document.body.classList.add(className)

    return () => {
      document.body.classList.remove(className)
    }
  }, [index])

  return (
    <StyledHero bgColor="--color-primary" color="--color-light" bgImage="0.08">
      <StyledMain as="div" fontSize="2.6rem">
        <StyledGrid columns="2" alignItems="center">
          <div>
            <h1 className="font-gradient-grey">{primary.hero_title.text}</h1>
            {primary.sub_title.text && <h3>{primary.sub_title.text}</h3>}
            <RichText render={primary.hero_content.raw} serializeHyperlink={GatsbyLink} />
            <Button as={Link} to={link} diap="true">
              {primary.button_label}
            </Button>
          </div>

          {image && (
            <figure>
              <GatsbyImage image={image} alt={primary.image.alt || primary.hero_title.text} loading="eager" />
            </figure>
          )}
        </StyledGrid>
      </StyledMain>
    </StyledHero>
  )
}

export default Hero

export const heroPageFragment = graphql`
  fragment heroPageFragment on PrismicPageDataBodyHero {
    id
    slice_type
    slice_label
    primary {
      hero_title {
        text
      }
      sub_title {
        text
      }
      hero_content {
        raw
      }
      button_label
      button_link {
        uid
        type
      }
      image {
        alt
        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      }
    }
  }
`
