import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import linkResolver from '../../lib/link-resolver'

import GatsbyLink from '../gatsby-link'
import RelatedArticles from '../related-articles'
import { Button, StyledSmallWrapper } from '../styles'

const Publications = ({ primary }) => {
  const link = linkResolver(primary.button_link)
  const { allPrismicArticle } = useStaticQuery(query)

  return (
    <StyledSmallWrapper as="section" fontSize="2.6rem">
      <h2 className="font-gradient-yellow header-size-large">{primary.publications_title.text}</h2>
      <RichText render={primary.publications_description.raw} serializeHyperlink={GatsbyLink} />

      <RelatedArticles articleList={allPrismicArticle?.edges} />
      <Button as={Link} to={link}>
        {primary.button_label}
      </Button>
    </StyledSmallWrapper>
  )
}

export default Publications

export const query = graphql`
  {
    allPrismicArticle(sort: { fields: data___publication_date, order: DESC }, limit: 3) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            featured_image {
              thumbnails {
                Thumbnail {
                  alt
                  gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
        }
      }
    }
  }
`

export const publicationsPageFragment = graphql`
  fragment publicationsPageFragment on PrismicPageDataBodyPublicaties {
    id
    slice_type
    slice_label
    primary {
      button_label
      button_link {
        uid
        type
      }
      publications_description {
        raw
      }
      publications_title {
        text
      }
    }
  }
`
