import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import InnerHTML from 'dangerously-set-html-content'

const StyledIframeVideoWrapper = styled.div`
  height: 0;
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 1.2em;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`

const StyledIframeWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.2em;
  }

  iframe {
    width: 100%;
    /* height: ${({ articleWidth }) => `calc((9 / 16) * ${articleWidth}px)`}; */
  }

  iframe + div {
    font-size: 1.8rem;
  }
`

const OEmbed = ({ primary }) => {
  if (!primary.link) return null

  const video = primary.link.provider_name === 'Vimeo' || primary.link.provider_name === 'YouTube' ? 1 : 0

  if (video) {
    return <StyledIframeVideoWrapper dangerouslySetInnerHTML={{ __html: primary.link.html }} />
  }

  // const [articleWidth, setArticleWidth] = useState()

  // function handleResize() {
  //   const article = document.getElementById('article').getBoundingClientRect()
  //   setArticleWidth(article.width)
  // }

  // useEffect(() => {
  //   handleResize()
  // }, [])

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize)

  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // })

  return (
    <StyledIframeWrapper>
      <InnerHTML html={primary.link.html} />
    </StyledIframeWrapper>
  )
}

export default OEmbed

export const embedArticleFragment = graphql`
  fragment embedArticleFragment on PrismicArticleDataBodyEmbed {
    id
    slice_label
    slice_type
    primary {
      link {
        html
        provider_name
      }
    }
  }
`
