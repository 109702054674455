import { graphql } from 'gatsby'

import FrontPage from '../templates/prismic-front-page'

export default FrontPage

export const query = graphql`
  query FrontPageQuery {
    prismicPage(data: { is_frontpage: { eq: true } }) {
      data {
        ...pageFragment
      }
    }
  }
`
