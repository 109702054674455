import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import linkResolver from '../lib/link-resolver'

import Layout from '../components/layout'
import Seo from '../components/seo'
import slices from '../components/prismic-slices'
import { StyledSmallWrapper } from '../components/styles'

const PageTemplate = ({ data }) => {
  const page = data.prismicPage
  const hero = page.data.body[0]?.slice_type === 'hero'

  return (
    <Layout>
      <Seo title={page.data.title.text} />
      {!hero && (
        <StyledSmallWrapper>
          <h1 className="font-gradient-blue header-breakout">{page.data.title.text}</h1>
        </StyledSmallWrapper>
      )}
      {page.data.body?.map((slice, i) => {
        const Slice = slices[slice.slice_type]
        return Slice ? <Slice key={slice.id} {...slice} index={i} /> : null
      })}
    </Layout>
  )
}

export default withPrismicPreview(PageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
])

export const query = graphql`
  query PageQuery($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      data {
        ...pageFragment
      }
      uid
    }
  }
`
