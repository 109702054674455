import React from 'react'
import styled from 'styled-components'

import CardRelatedArticle from './card-related-article'

const StyledRelatedArticles = styled.div`
  margin: 4rem 0;
`

const RelatedArticles = ({ articleList }) => {
  if (!articleList || !articleList.length) {
    return null
  }

  return (
    <StyledRelatedArticles>
      {articleList.map(({ node }) => (
        <CardRelatedArticle key={node.uid} node={node} />
      ))}
    </StyledRelatedArticles>
  )
}

export default RelatedArticles
