import React from 'react'
import { Link } from 'gatsby'
import ArticleImage from './article-image'
import { StyledCard } from './styles'

const CardRelatedArticle = ({ node }) => (
  <StyledCard variant="row">
    <Link to={`/${process.env.GATSBY_PRISMIC_ARTICLE_PAGE}/${node.uid}`}>
      <ArticleImage
        image={node.data.featured_image?.thumbnails.Thumbnail}
        alt={
          node.data.featured_image.thumbnails.Thumbnail.alt ||
          node.data.featured_image.alt ||
          node.data.title.text
        }
        aspectRatio={{ width: 1, height: 0.6753 }}
      />
      <header>
        <h3>{node.data.title.text}</h3>
      </header>
    </Link>
  </StyledCard>
)

export default CardRelatedArticle
