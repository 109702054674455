import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import GatsbyLink from '../gatsby-link'

const Content = ({ primary }) => <RichText render={primary.content.raw} serializeHyperlink={GatsbyLink} />

export default Content

export const contentArticleFragment = graphql`
  fragment contentArticleFragment on PrismicArticleDataBodyContent {
    id
    slice_label
    slice_type
    primary {
      content {
        raw
      }
    }
  }
`
