import Content from './content'
import OEmbed from './oembed'
import ContentSection from './centent-section'
import Hero from './hero'
import Publications from './publications'
import PageContent from './page-content'

/* prettier-ignore */
export {
  Content,
  OEmbed,
  ContentSection,
  Hero,
  Publications,
  PageContent
}

const slices = {
  content: Content,
  embed: OEmbed,
  content_sectie: ContentSection,
  hero: Hero,
  publicaties: Publications,
  page_content: PageContent,
}

export default slices
