import React from 'react'
import { Button } from './styles'
import { useLocation } from '@reach/router'

const WhitePaperButton = ({ children, whitePaper }) => {
  const { pathname } = useLocation()

  function gaEventHandle() {
    if (typeof window.gtag !== 'function') return
    window.gtag('event', 'download_whitepaper', {
      pathname,
    })
  }

  return (
    <Button as="a" download href={whitePaper.url} target="_blank" diap="true" onClick={() => gaEventHandle()}>
      {children}
    </Button>
  )
}

export default WhitePaperButton
